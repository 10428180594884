.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-links {
    display: flex;
    gap: 15px;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #ccc;
}

.chat-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-button:hover {
    background-color: #0056b3;
}
