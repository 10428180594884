.header-container {
    display: flex;
    justify-content: space-between; /* Espacio entre elementos */
    align-items: center; /* Alinea verticalmente en el centro */
    padding: 10px; /* Espaciado interno */
    background-color: #F6FFF1;
}

.logo-web {
    width: 300px; /* Ajusta el tamaño del logo */

}
.search-bar-Icon{
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
    
}
.search-bar input{
    background-color: white;
    border: 1;
    border-radius: 2px;
    font-size: 18px;
    padding: 15px;
    height: 10%;
    width: 50%;
}
.search-bar{
    flex: 1; /* Deja que la barra de búsqueda ocupe todo el espacio disponible */
    display: flex;
    border: 1px;
    margin-left: 50px;
}

.cart, .profile {
    width: 40px; /* Tamaño ajustado */
    margin-right: 50px;
}
