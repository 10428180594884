.cart-container {
    padding: 20px;
}

.cart-list {
    display: grid;
    gap: 20px;
}

.cart-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
}

.cart-thumbnail img {
    width: 100px; /* Ajusta el tamaño de la imagen según sea necesario */
    height: auto;
}

.cart-details {
    display: flex;
    flex-direction: column;
}

.cart-details p {
    margin: 5px 0;
}

.cart-quantity {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.cart-total {
    flex-grow: 1; /* Para que ocupe todo el espacio disponible */
    text-align: right; /* Alinear el texto del total a la derecha */
}

.fondo-cart{
    background-color: #F6FFF1;
    padding: 20px;

}