.textBox{
    background-color: #cccccc40;
    border-color: slategray;
    border-radius: 30px;
    width: 100%;
    padding: 15px;
    border-width: 0px;
    margin-bottom: 20px;
}

.textBox:focus{
    outline: 1px solid cornflowerblue;
    box-shadow: 0 0 10px cornflowerblue;
}