
.home{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000000;
  }
  
  .web{
    background-color: #F6FFF1;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  
  .logo{
    position: absolute;
    width: 30%;
    height: 10%;
    margin-top: 3% ;
    margin-left: 3%;
  }
  .fondo{
    width: 100%;
    height: 100%;
    opacity: 0.4; 
    position: absolute;
    background-color: #000000;
  }
  
  .button-left {
    font-size: 36px;
    border: 2px solid #000000;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%; 
    z-index: 1; /* Asegura que el botón esté por encima de la imagen */
    width: 100px;
    height: 60px;
    transform: translate(-200%,0);
  }
  
  .button-right {
    font-size: 36px;
    border: 2px solid #000000;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%; 
    z-index: 1; /* Asegura que el botón esté por encima de la imagen */
    width: 100px;
    height: 60px;
    padding-top: 10px;
    transform: translate(100%,0);
  }
  
  .search{
    top: 35%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
  